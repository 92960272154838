<template>
<v-main>
  <v-row class="text-center mt-5">
    <v-col cols="12">
    <h3 class="display-3">Oj! Sidan kan inte hittas.</h3>
    </v-col>
  </v-row>
</v-main>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
